import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import "./index.css";
import { updateKYC, getKYC, toggleStatusModal } from "../../store/actions/Auth";

class ApproveKYC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycData: [],
      publicAddress: "",
      domainName:"",
      kycStatus: true,
    };
  }

  async componentDidMount() {
    await this.props.getKYC("updated");
  }

  componentWillReceiveProps({ kycData }) {
    this.setState({ kycData });
  }

  handleEditChange = (e) => this.setState({ kycStatus: e.target.value });

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");
  copiedDomain = () => EventBus.publish("success", "Domain Address Copied");
  verifyKYC = (publicAddress, open) => {
    this.props.toggleStatusModal(true)
  };

  submitApproval = () => {
    let { publicAddress,domainName } = this.state;
    if(!publicAddress) return EventBus.publish("error", "Please Add Wallet Address");
    if(!domainName) return EventBus.publish("error", "Please Add Username");
    this.props.updateKYC({address:publicAddress,domainName});
  };

  onChangeAddress = (e) =>{
    let input = e.target.value;
    let output = input.substring(0, 3); // checks first three char of address
     if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        this.setState({publicAddress:result});
        console.log(result);
     } else {
        this.setState({publicAddress:e.target.value});
        console.log(input);
    }
  }

  render() {
    let { isStatusModal } = this.props;

    const columns = [
      {
        id: "publicAddress",
        Header: "Wallet Address",
        accessor: (kycData) =>
          kycData["address"] ? (
            <CopyToClipboard
              text={kycData["address"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${kycData[
                "address"
              ].substring(0, 8) +
                "....." +
                kycData["address"].substring(
                  34,
                  kycData["address"].length
                )}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
        width:180,
        filterable: false,
      },
      {
        id: "domainName",
        Header: "Domain",
        accessor: (kycData) => (kycData["domainName"] ? 
        <CopyToClipboard
              text={kycData["domainName"]}
              onCopy={this.copiedDomain}>
              <a className="public-address">
                  {kycData["domainName"]}
              </a>
        </CopyToClipboard>
        : "-"),
        width:230,
        filterable: false,
      },
      {
        id: "status",
        Header: "Status",
        accessor: (kycData) => (kycData["status"] ? kycData["status"] : "-"),
        width:120,
        filterable: false,
      },
      {
        id: "whitelistedAt",
        Header: "Whitelisted At",
        accessor: (kycData) => (kycData["createdAt"] ? moment(kycData["createdAt"]).format("MMMM Do YYYY") : "-"),
        width:120,
        filterable: false,
      },
    ];

    return (
      <div className="content">
        <div className="main-container mintedNFT">
          <div className="edit-add">
            <div className="edit-add-title2">
              <div className="mb-3">
                <h1>Whitelist</h1>
              </div>
              <div className="mb-3">
                <Button
                      className="add-btn col-4"
                      type="button"
                      onClick={this.verifyKYC}
                    >
                      Add Whitelist
                </Button>
              </div>
            </div>
            <Fragment>
              <div className="main-container-head mb-3">
                <ReactTable
                  className="table"
                  data={this.state.kycData}
                  resolveData={(kycData) => kycData.map((row) => row)}
                  columns={columns}
                  minRows={20}
                  filterable={true}
                />
              </div>
            </Fragment>
          </div>
        </div>
        {/* ---------------VIEW MODAL--------------- */}

        <Modal
          isOpen={isStatusModal}
          toggle={() => this.props.toggleStatusModal(false)}
          className="main-modal drop-modal"
          centered
        >
          <ModalHeader toggle={() => this.props.toggleStatusModal(false)}>
            <div className="modal-logo">
              <img
                src={require("../../assets/img/logo.png")}
                alt="modal-logo"
              />
            </div>
            <div className="drop-modal-title text-center">
              <p className="">Add Whitelist</p>
            </div>
            <div className="drop-modal-line">
              <hr />
            </div>
          </ModalHeader>
          <ModalBody className="modal-body drop-modal-body">
            <div className="row">
              <div className="col-12">
                <input placeholder="Wallet Address" value={this.state.publicAddress} onChange={e=>this.onChangeAddress(e)}/>
              </div>
              <div className="col-12 mt-1">
                <input placeholder="User Name" value={this.state.domainName} onChange={e=>this.setState({domainName:e.target.value})}/>
              </div>
              <div className="col-12 mt-5 d-flex justify-content-around">
                <Button
                  className="cancel-btn col-4"
                  type="button"
                  onClick={() => this.props.toggleStatusModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="add-btn col-4"
                  type="button"
                  onClick={this.submitApproval}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateKYC,
  getKYC,
  toggleStatusModal,
};

const mapStateToProps = ({ Auth }) => {
  let { kycData, isStatusModal } = Auth;
  console.log("**************** kycData",kycData);
  return { kycData, isStatusModal };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveKYC);
