import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./index.css";
import { web3 } from "../../store/web3";
import { ICO } from "../../store/contract/index.js";
import { toFixed } from '../../store/numberFormat';
import { setLoader } from "../../store/actions/Auth.js";

class TXDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerData: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.getTransactionData();
  }

  myEvents = () => {
    return new Promise(async (resolve, reject) => {
      let newData = [];
      const from = (await web3.currentProvider.enable())[0];
      ICO.events.TokensPurchased({ filter: from, fromBlock: 0 }, function (error, event) {
        let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
        newData.push(data);
        resolve(newData);
      });
      if (newData.length <= 0) {
        this.setState({ isLoading: false });
        this.props.setLoader({ status: false });
      }
    })
  }
  getTransactionData = async () => {
    try {
      this.props.setLoader({
        message: "Loading Data ...",
        status: true,
      });
      let result = await this.myEvents();
      this.setState({ registerData: result });
      this.setState({ isLoading: false });
      this.props.setLoader({ status: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");
  copiedTransaction = () => EventBus.publish("success", "Transaction Hash Copied");

  render() {
    let { registerData } = this.state;
    console.log("********** registerData", registerData)
    const columns = [
      {
        id: "walletAddress",
        Header: "Wallet Address",
        accessor: (registerData) =>
          registerData["beneficiary"] ? (
            <CopyToClipboard
              text={registerData["beneficiary"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${registerData[
                "beneficiary"
              ].substring(0, 8) +
                "....." +
                registerData["beneficiary"].substring(
                  34,
                  registerData["beneficiary"].length
                )}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
      },
      {
        id: 'tx',
        Header: 'TX Hash',
        accessor: registerData => registerData['transactionHash'] ? (
          <CopyToClipboard
            text={registerData["transactionHash"]}
            onCopy={this.copiedTransaction}
          >
            <a className="public-address">{`${registerData["transactionHash"]}`}</a>
          </CopyToClipboard>
        ) : (
          "-"
        ),
      },
      {
        id: 'amountDonated',
        Header: 'Amount Donated',
        accessor: registerData => registerData['weiAmount'] ? <a className="public-address">{`${toFixed(web3.utils.fromWei(registerData['weiAmount'].toString(), 'ether'), 'price')}`}</a> : "-",
      }
    ];

    return (
      <div className="content">
        <div className="main-container mintedNFT">
          <div className="edit-add">
          <div className="main-container-head mb-3">
            <h1>Donations Details</h1>
          </div>
          <Fragment>
            <div className="main-container-head mb-3">
              <ReactTable
                className="table"
                data={registerData}
                resolveData={(registerData) => registerData.map((row) => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            </div>
          </Fragment>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TXDetails);
