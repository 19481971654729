import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import {setToken} from "../axios";
import { setLoginData, setKYC, toggleStatusModal } from '../actions/Auth';

/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/users/loginSuperAdmin', payload });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "superAdmin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    setToken(response["data"]["body"]["token"]);
    yield put(setLoginData(response["data"]["body"]["token"]));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message']);
    // setTimeout(() => window.location.href = '/home/addWhiteList', 1000);
  }
};


/*========== KYC FUNCTIONS =============*/

function* getKYC() {
  const { error, response } = yield call(getCall, '/users/getWhitelisted');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setKYC(response['data']['body']));
  }
};

export function* updateKYC({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/users/whitelist",
    payload,
  });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYC" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

function* actionWatcher() {
  yield takeEvery('LOGIN_USER', login);
  yield takeEvery('GET_KYC', getKYC);
  yield takeEvery('UPDATE_KYC', updateKYC);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
