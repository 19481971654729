import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { setLoader } from "../../store/actions/Auth.js";
import { Approve, ApproveAddress } from "../../store/contract";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import './index.css';

class Approved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      domainName: "",
    };
  };

  handleEditChange = async (e) => this.setState({ [e.target.name]: e.target.value });

  submitTransfer = async () => {
    try {
      let { address } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (address == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      /** Approval **/
      let isSuperAdmin = await (Approve.methods.isSuperAdmin().call({from:publicAddress}));

      if (isSuperAdmin == true) {
        let isApprove = await (Approve.methods.isWhitelist(address).call());

        if (isApprove == true) {
          EventBus.publish("error", `Address already approved`);
          return;

        } else {
          this.props.setLoader({
            message: "Approval in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from: publicAddress,
              value: 0,
              to: ApproveAddress,
              gas: 2000000,
              data: Approve.methods
                .addWhitelist(address)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.setState({ address: "" });
              this.props.setLoader({ status: false });
              EventBus.publish("success", `Approved Successfully`);
            });
        }
      } else {
        EventBus.publish("error", `Please approve through Super Admin Address!`);
        return;
      }

    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  render() {
    let { address } = this.state;

    return (
      <div className="content">
        <div className="main-container mint-new">
          <div className="mint-new-NFT">
            <div className="edit-add">
              <div className="edit-add-title col-12">
                <h1>Approve User</h1>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitTransfer}>
                    <div className="edit-add-field mt-4">
                      <TextValidator
                        fullWidth
                        type="text"
                        name="address"
                        value={address}
                        variant="filled"
                        id="standard-full-width"
                        className="form-input-field"
                        onChange={this.handleEditChange}
                        label={
                          <label className="labelColorValidator">
                            Wallet Address
                          </label>
                        }
                      />
                    </div>
                    <div className="edit-add-buttons mt-4">
                      <Button
                        className="submit-btn"
                        type="submit"
                        onSubmit={this.submitTransfer}
                      >
                        Submit
                      </Button>
                    </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Approved);