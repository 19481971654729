const { web3 } = require('../web3');
const { env } = require('../config');

const ApproveData = require(`./${env}/Approve.json`);
const TokenData = require(`./${env}/Token.json`);
const ICOData = require(`./${env}/ICO.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const ApproveABI = ApproveData['abi'];
const ApproveAddress = ApproveData['address'];
const Approve = new web3.eth.Contract(ApproveABI, ApproveAddress);

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const ICOABI = ICOData['abi'];
const ICOAddress = ICOData['address'];
const ICO = new web3.eth.Contract(ICOABI, ICOAddress);


module.exports = {
  Approve, ApproveABI, ApproveAddress,
  Token, TokenABI, TokenAddress,
  ICO, ICOABI, ICOAddress,
};
