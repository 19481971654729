import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import * as ethUtil from "ethereumjs-util";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Redirect } from "react-router-dom";
import { web3 } from "../../store/web3";
import logo from '../../assets/img/logo.png';
import XDC from '../../assets/img/xdc.svg';
import { login } from "../../store/actions/Auth";
import { networkId, message } from "../../store/config";
import { Token, Approve } from "../../store/contract/index";

import "./index.css";
import { Fragment } from "react";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      netId: "",
      address: "",
    };
  }

  async componentDidMount() {
    web3.eth.net.getId((err, netId) => {
      this.setState({ netId });
    });
    if (!window.ethereum) {
      EventBus.publish("error", "Please install XDC-PAY");
      return;
    }
    this.checkAddresses();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.auth !== this.props.auth){
      if(this.props.auth) this.props.history.push('/home');
    }
  }

  checkAddresses = async () => {
    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
  };

  handleLogin = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install XDC-PAY");
      return;
    }

    let { address } = this.state;
    if (!address) return EventBus.publish("error", "Wallet Address Not Found");
    this.props.login({address});
    // setTimeout(() => this.props.history.push('/home'), 1000);
  };

  render() {
    let { netId } = this.state;
    let {isLogin} = this.props;
    if(isLogin == true) return  <Redirect to="/home/addWhiteList" />
    return (
      <div className="login-page">
        <div className="login-area mx-auto">
          <div className="login-form">
            <h4 className="login-title">
              <img src={logo} alt="logo" className="logoLogin" />
              Trading bot
            </h4>
            <h1>Welcome Super Admin</h1>
            <p className="text-white">
            Access to manage the Trading Bot dApp
            </p>
            {netId != networkId ? (
              <div className="login-text">
                <p>{message}</p>
              </div>
            ) : (
              <Fragment>
                <ValidatorForm
                  className="validator-form"
                  onSubmit={this.handleLogin}
                >
                  <Button
                    type="Submit"
                    variant="contained"
                    className="text-white login-btn"
                  >
                    Login with XDCPay Wallet
                    <img src={XDC} alt="xdc" className='xdc' />
                  </Button>
                </ValidatorForm>
              </Fragment>
            )}
          </div>
        </div>
        <div className='bgLoginPg'></div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  login,
};

const mapStateToProps = ({ Auth }) => {
  let {auth,isLogin} = Auth;
  return {auth,isLogin};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
